import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
import { CreateEditCasePostBottomBarConfig } from
  'src/app/components/create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import { PersonalMediaGalleryDocumentWithStageOfTreatment } from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AttachmentMimeType, ImageAttachment, isVideo } from 'src/app/services/attachments.model';
import { GalleryConfig } from 'src/app/modules/file-select/services/image-gallery.model';
import { ImageGalleryService } from 'src/app/modules/file-select/services/image-gallery.service';
import { GroupedImagesByStageOfTreatment } from 'src/app/services/utils/ui-utils.service.interface';

interface ListHeader {
  isHeader: boolean;
  index: number;
  title: string;
}

type FlattenedImage = ListHeader | PersonalMediaGalleryDocumentWithStageOfTreatment;

@Component({
  selector: 'app-reorder-case-media-dialog',
  templateUrl: './reorder-case-media-dialog.component.html',
  styleUrls: ['./reorder-case-media-dialog.component.scss'],
})
export class ReorderCaseMediaDialogComponent implements OnInit {

  @Input() groupedImages: Array<GroupedImagesByStageOfTreatment>;
  @Input() flattenedImages: Array<FlattenedImage>;
  @Input() watermarked: boolean;

  imagesInitialOrder: Array<string> = [];
  bottomBarConfig: CreateEditCasePostBottomBarConfig;

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    private imageGalleryService: ImageGalleryService,
  ) { }

  ngOnInit(): void {

    this.bottomBarConfig = {
      images: {
        avaliable: () => { return true },
      },
      videos: {
        avaliable: () => { return true },
      }
    }

    this.flattenedImages = this.getFlattenedImages();
    this.imagesInitialOrder = this.getImagesOrder();
  }

  getFlattenedImages(): Array<FlattenedImage> {

    let flattenedItems = [];

    this.groupedImages?.forEach((group, index) => {
      flattenedItems = [...flattenedItems, ...[
        { isHeader: true, index: index, title: group.title },
        ...group.items
      ]]
    });

    return flattenedItems;
  }

  doReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
    this.flattenedImages = ev.detail.complete(this.flattenedImages);
  }

  getRightOrder(): Array<GroupedImagesByStageOfTreatment> {
    const rightOrder = this.flattenedImages.reduce((accumlator, item) => {
      if ((item as ListHeader)?.isHeader) {
        const { id, title } = this.groupedImages[(item as ListHeader)?.index];
        accumlator.push({ id, title, items: [] });
      } else {
        (item as any).stageOfTreatmentId = accumlator[accumlator.length - 1]?.id || '';
        accumlator[accumlator.length - 1].items.push(item);
      }
      return accumlator;
    }, []);

    return rightOrder;
  }

  get isMediaOrderChanged(): boolean {
    return JSON.stringify(this.imagesInitialOrder) !==
      JSON.stringify(this.getImagesOrder());
  }

  get disableSaveBtn(): boolean {
    return !this.isMediaOrderChanged;
  }

  save(): void {
    this.modalController.dismiss(this.getRightOrder());
  }

  async onBack(): Promise<void> {

    const shouldProceedWithBack = await this.continueIfStateIsNotSaved();

    if (shouldProceedWithBack?.role === 'confirm' || shouldProceedWithBack === true) {
      this.modalController.dismiss();
    }
  }

  async continueIfStateIsNotSaved(): Promise<any> {

    if (this.isMediaOrderChanged) {
      return this.promptUserAboutNotSavedState();
    } else {
      return true;
    }
  }

  async promptUserAboutNotSavedState(): Promise<any> {
    const title = await this.appTranslationService.get('app.dialogs.ReorderCaseMediaDialog.unsaved-changes-warning-title');
    const text = await this.appTranslationService.get('app.dialogs.ReorderCaseMediaDialog.unsaved-changes-warning-text');
    const confirmButtonText =
      await this.appTranslationService.get('app.dialogs.ReorderCaseMediaDialog.unsaved-changes-warning-confirm-button-text');
    const rejectButtonText =
      await this.appTranslationService.get('app.dialogs.ReorderCaseMediaDialog.unsaved-changes-warning-reject-button-text');

    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: confirmButtonText,
        role: 'confirm'
      },
      {
        text: rejectButtonText,
        role: 'cancel',
        cssClass: 'secondary'
      }]
    });

    alert.present();
    return alert.onDidDismiss();
  }

  previewImage(image: PersonalMediaGalleryDocumentWithStageOfTreatment): void {

    let attachments: Array<ImageAttachment>;

    if (image) {
      attachments = [image].map(attachment => {
        return {
          ...attachment,
          url: attachment.previewUrl,
          fullImageUrl: attachment?.fullUrl,
          videoUrl: isVideo(attachment.mimeType) ? attachment?.fullUrl : null,
          mimeType: isVideo(attachment.mimeType) ? AttachmentMimeType.MP4 : attachment.mimeType
        }
      });

      if (this.watermarked) {
        attachments = attachments.map(img => {
          img.fullUrl = img?.fullWatermarkUrl;
          img.previewUrl = img?.watermarkedPreviewUrl;
          img.videoUrl = isVideo(img.mimeType) ? img?.fullUrl : null
          return img;
        })
      }
    }

    const galleryConfig: GalleryConfig = {
      images: attachments,
      allowImageEdit: false,
      reviewMode: false,
      scope: null,
      imageGalleryStrategy: null
    };

    this.imageGalleryService.openImageGallery(galleryConfig);
  }

  private getImagesOrder(): Array<string> {

    const imagesOrder = [];

    this.flattenedImages?.forEach(image => {

      const headerTitle = (image as any)?.title;
      const imageId = (image as any)?._id;

      if (headerTitle) {
        imagesOrder.push(headerTitle);
      }

      if (imageId) {
        imagesOrder.push(imageId);
      }
    });

    return imagesOrder;
  }

}
