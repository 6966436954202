import { Inject, Injectable } from '@angular/core';
import { ContentParentType, CreateContentStrategy, CreatePostAction, PostData, UpdatePostData } from '../create-content';
import { AOEvent, AOEventParticipantsSuccessResponse, EventPost, Participant } from 'src/app/services/yeti-protocol/event';
import { CreateEventContentService } from '../event/create-event-content-service.service';
import {
  CreatePostRequest,
  CreatePostSuccessResponse,
  ParentType,
  Post,
  PostType,
  UpdatePostRequest,
  UpdatePostSuccessResponse
} from '../../yeti-protocol/chatter-api';
import { ChatterApiService } from '../../chatter-api.service';
import { ToastMode, ToastService } from '../../toast.service';
import { NavControllerService } from '../../nav-controller.service';
import { ClinicalCase } from '../../yeti-protocol/clinical-case';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
import { PostsDataService } from '../../posts/posts-data.service';
import { AppTranslationService } from '../../app-translation.service';
import { EventsService } from 'src/app/services/events/events.service';
// import { AOEventParticipantType, AOEventParticipantsSuccessResponse } from '../../yeti-protocol/event';

@Injectable({
  providedIn: 'any',
})
export class CreateEventContentStrategy extends CreateContentStrategy {

  facultyOnly: boolean;

  constructor(
    private createEventContentService: CreateEventContentService,
    private chatterApiService: ChatterApiService,
    private toast: ToastService,
    private navController: NavControllerService,
    private modalController: ModalController,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private postsDataService: PostsDataService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    private eventsService: EventsService,
  ) {
    super();

    this.contentParentType = ContentParentType.EVENT;
  }

  openCreatePost(
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean
  ): void {
    this.createEventContentService.openCreateEventPostPage(
      this.parent?._id,
      this.post?._id,
      action,
      postContentText,
      clinicalCase,
      replaceUrl,
      this.facultyOnly);
  }

  openCreateCase(caseId?: string, replaceUrl?: boolean): void {
    this.createEventContentService.openCreateEventCasePage(this.parent?._id, caseId, replaceUrl, this.facultyOnly, this.shareCaseInPost);
  }

  openAddImages(): void {
    this.createEventContentService.openCreateEventPostPage(
      this.parent?._id,
      null,
      CreatePostAction.ADD_IMAGES,
      undefined,
      undefined,
      undefined,
      this.facultyOnly);
  }

  openAttachDocument(): void {
    this.createEventContentService.openCreateEventPostPage(
      this.parent?._id,
      null,
      CreatePostAction.ATTACH_DOCUMENT,
      undefined,
      undefined,
      undefined,
      this.facultyOnly);
  }

  openAddVideo(): void {
    this.createEventContentService.openCreateEventPostPage(
      this.parent?._id,
      null,
      CreatePostAction.ADD_VIDEO,
      undefined,
      undefined,
      undefined,
      this.facultyOnly);
  }

  get isPostTypeAttachment(): boolean {
    return this.post?.type === PostType.attachment;
  }

  get isPostTypeLink(): boolean {
    return this.post?.type === PostType.link;
  }

  get isPostTypeText(): boolean {
    return this.post?.type === PostType.text;
  }

  createPost(postData: PostData, preventNavigation?: boolean): Promise<Post> {
    const mutablePostData = {...postData};
    mutablePostData.parentIds = this.parentIds;
    mutablePostData.parentType = ParentType.EVENT;
    mutablePostData.facultyOnly = this.facultyOnly || false;

    if (!mutablePostData?.parentIds?.length) {
      console.error('parentId is mandatory');
      return Promise.reject();
    }

    if (!mutablePostData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {

      this.chatterApiService.createPost(mutablePostData as CreatePostRequest)
        .then(async (createEventPostRes: CreatePostSuccessResponse) => {
          if (createEventPostRes) {
            this.toast.show('app.components.CreatePost.post-successfull');
          }

          this.post = createEventPostRes?.result;
          this.postsDataService.triggerNewPostAddedAction(this.post);

          try {

            await this.modalController?.dismiss({ customClose: true });

            if (!preventNavigation) {
              this.navigateToPost();
            }
          } catch (err) {
            console.log(err);
          }

          resolve(this.post);
        }).catch(err => {
          this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
          reject(err);
        });
    });
  }

  createPostWithACase(postWithCaseData: PostData, preventNavigation?: boolean): Promise<EventPost> {
    const mutablePostWithCaseData = {...postWithCaseData};
    mutablePostWithCaseData.parentIds = this.parentIds;
    mutablePostWithCaseData.parentType = ParentType.EVENT;
    mutablePostWithCaseData.facultyOnly = this.facultyOnly || false;

    if (!mutablePostWithCaseData?.parentIds?.length) {
      console.error('parentIds is mandatory');
      return Promise.reject('parentIds is mandatory');
    }

    if (!mutablePostWithCaseData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    return new Promise((resolve, reject) => {
      this.chatterApiService.createPost(mutablePostWithCaseData as CreatePostRequest)
        .then(async (createEventPostRes: CreatePostSuccessResponse) => {
          if (createEventPostRes) {
            this.toast.show('app.components.CreatePost.post-successfull');
          }

          this.post = createEventPostRes?.result;
          this.postsDataService.triggerNewPostAddedAction(this.post);

          this.modalController?.dismiss({ customClose: true });

          if (!preventNavigation) {
            this.navigateToPost();
          }

          resolve(this.post);
        }).catch(err => {
          this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
          reject(err);
        });
    });
  }

  updatePost(updatePostData: UpdatePostData): Promise<EventPost> {

    updatePostData.parentId = this.post.parentId;
    updatePostData.parentType = ParentType.EVENT;

    if (!updatePostData?.parentId?.length) {
      console.error('parentIds is mandatory');
      return Promise.reject('parentIds is mandatory');
    }

    if (!updatePostData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    return new Promise((resolve, reject) => {
      this.chatterApiService.updatePost(this.post?._id, updatePostData as UpdatePostRequest)
        .then(async (updatePostRes: UpdatePostSuccessResponse) => {

          this.post = updatePostRes?.result;
          this.postsDataService.triggerPostEditedAction(this.post);
          resolve(this.post);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  getParentMembers(searchTerm: string,
    start?: number,
    count?: number,
    excludedUserIds?: Array<string>): Promise<Array<Participant>> {

    if (!this.parent?._id) {
      return Promise.resolve([]);
    }

    const eventAttendeesPromise =
      this.eventsService.searchEventAttendees(this.parent?._id, searchTerm, start, count, excludedUserIds, !!this.facultyOnly);
    return new Promise((resolve, reject) => {
      eventAttendeesPromise.then((res: AOEventParticipantsSuccessResponse) => {
        resolve(res.result.map(attendee => attendee.participantInfo));
      }).catch(err => {
        reject(err);
      })
    })

  }

  async promptUserAboutNotSavedState(): Promise<any> {
    const title = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-title');
    const text = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-text');
    const confirmButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-confirm-button-text');
    const rejectButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-reject-button-text');

    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: confirmButtonText,
        role: 'confirm'
      },
      {
        text: rejectButtonText,
        role: 'cancel',
        cssClass: 'secondary'
      }]
    });

    alert.present();
    return alert.onDidDismiss();
  }

  determinePostType(url: string, attachments: Array<string>): string {
    if (attachments?.length) {
      return PostType.attachment;
    } else if (url) {
      return PostType.link;
    } else {
      return PostType.text;
    }
  }

  get parentIds(): Array<string> {

    const index = this.parentsData?.findIndex(data => data?.parentsType === ParentType.EVENT);

    if (index > -1) {
      const parentsIds = this.parentsData[index].parents?.map(group => {
        return group?._id;
      })?.filter(id => id !== null && id !== undefined) || [];

      return parentsIds;
    }

    return [];
  }

  get parent(): AOEvent {
    const index = this.parentsData?.findIndex(data => data?.parentsType === ParentType.EVENT);

    if (index > -1) {
      if (this.parentsData[index]?.parents?.length === 1) {
        return this.parentsData[index].parents?.[0] as AOEvent;
      } else { // if there is multiple parents return null
        return null;
      }
    }

    return null;
  }

  get allowAnonymousPost(): boolean {
    return true;
  }

  // fetchEventContributors(): void {
  //   this.eventsService.getAOEventContributors(this.parent?._id, 0, 9)
  //     .then((aoEventContributorsRes: AOEventParticipantsSuccessResponse) => {
  //       this.aoEventContributors = aoEventContributorsRes.result;
  //       this.totalAoEventContributors = aoEventContributorsRes.totalItemsCount;
  //     }).catch(err => {
  //       console.error(err);
  //     });
  // }

  // fetchEventParticipants(eventId: string): void {
  //   this.eventsService.getAOEventParticipants(this.parent?._id, AOEventParticipantType.PARTICIPANT, 0, 6)
  //     .then((aoEventParticipantsRes: AOEventParticipantsSuccessResponse) => {
  //       this.eventParticipants = aoEventParticipantsRes.result.map(res => res.participantInfo);
  //       this.totalEventParticipants = aoEventParticipantsRes.totalItemsCount;
  //     }).catch(err => {
  //       console.error(err);
  //     });
  // }

  private navigateToEvent(): void {

    this.navController.back();

    setTimeout(() => {
      const eventUrl = `${this.contextService.currentContext.homePath}/event/${this.parent?._id}`;

      if (window.location.href.indexOf(eventUrl) === -1) {
        this.router.navigateByUrl(eventUrl, { replaceUrl: true });
      }
    }, 100);
  }

  private navigateToPost(): void {

    const postType = (this.post as EventPost).type === PostType.case ? 'case' : 'post';
    const postUrl = `${this.contextService.currentContext.homePath}/event/${postType}/${this.post?._id}`;

    if (window.location.href.indexOf(postUrl) === -1) {
      this.router.navigateByUrl(postUrl, { replaceUrl: true });
    }
  }
}
