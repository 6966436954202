import {
  ParentType,
  PinPostSuccessResponse,
  PostListItem,
  PostsListResponse,
  UnpinPostSuccessResponse
} from 'src/app/services/yeti-protocol/chatter-api';
import { PostsFeed } from '../posts-feed/posts-feed.actions';

export namespace EventFeedFacultyOnly {

  export class FetchPostsFeed {
    static readonly type = '[Event Feed Faculty Only] Fetch posts feed';
    constructor(public payloadParams: PostsFeed.FetchPostsFeedPayload) { }
  }

  export class FetchPostsFeedSuccess {
    static readonly type = '[Event Feed Faculty Only] Fetch posts feed success';
    constructor(
      public payloadParams: PostsFeed.FetchPostsFeedPayload,
      public response: PostsListResponse
    ) { }
  }

  export class FetchPostsFeedFailed {
    static readonly type = '[Event Feed Faculty Only] Fetch posts feed failed';
  }

  export class InsertPostsFeedItemBeforeIndex {
    static readonly type = '[Event Feed Faculty Only] Insert posts feed item before index';
    constructor(
      public item: PostListItem,
      public index: number
    ) { }
  }

  export class RemovePostsFeedItem {
    static readonly type = '[Event Feed Faculty Only] Remove posts feed item at index';
    constructor(
      public item: PostListItem
    ) { }
  }

  export class UpdatePostsFeedItem {
    static readonly type = '[Event Feed Faculty Only] Update posts feed item';
    constructor(
      public item: PostListItem
    ) { }
  }

  export class SetPinnedAndNotPinnedPostsFeedItems {
    static readonly type = '[Event Feed Faculty Only] Set pinned and not pinned posts feed items';
    constructor() { }
  }

  export class PinPostsFeedItem {
    static readonly type = '[Event Feed Faculty Only] Pin posts feed item';
    constructor(
      public itemId: string
    ) { }
  }

  export class PinPostsFeedItemSuccess {
    static readonly type = '[Event Feed Faculty Only] Pin posts feed item success';
    constructor(
      public pinItemSuccessResponse: PinPostSuccessResponse
    ) { }
  }

  export class PinPostsFeedItemFailed {
    static readonly type = '[Event Feed Faculty Only] Pin posts feed item failed';
  }

  export class UnpinPostsFeedItem {
    static readonly type = '[Event Feed Faculty Only] Unpin posts feed item';
    constructor(
      public itemId: string
    ) { }
  }

  export class UnpinPostsFeedItemSuccess {
    static readonly type = '[Event Feed Faculty Only] Unpin posts feed item success';
    constructor(
      public unpinItemSuccessResponse: UnpinPostSuccessResponse
    ) { }
  }

  export class UnpinPostsFeedItemFailed {
    static readonly type = '[Event Feed Faculty Only] Unpin posts feed item failed';
  }

  export class SetParentTypeAndParentId {
    static readonly type = '[Event Feed Faculty Only] Set posts parent type and parent id';
    constructor(
      public parentType: ParentType,
      public parentId: string
    ) { }
  }

  export class UpdatePostsFeedItemsOwnerFollowingStatus {
    static readonly type = '[Event Feed Faculty Only] Update posts feed items owner following status';
    constructor(public userId: string, public isFollower: boolean) { }
  }
}
