// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topic-select-container .topic-input-field-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: 35px;
  -moz-column-gap: 13px;
       column-gap: 13px;
  justify-items: center;
  padding: 8px 10px;
  border: 1px solid var(--mi-color-text-lite);
  border-radius: 6px;
  position: relative;
  align-items: center;
  z-index: 10;
}
.topic-select-container .topic-input-field-container.valid {
  border: 1px solid var(--mi-success);
}
.topic-select-container .topic-input-field-container .input-fields-container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  grid-template-columns: auto min-content auto;
  grid-template-rows: min-content;
  justify-items: start;
  align-items: center;
  justify-self: start;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
}
.topic-select-container .topic-input-field-container .input-fields-container input {
  border: none;
  outline: none;
  pointer-events: none;
  background: transparent;
}
.topic-select-container .topic-input-field-container .input-fields-container app-case-classification-chip {
  display: inline-block;
  --case-classification-text-color: var(--mi-grey-color-13);
  --case-classification-text-size: var(--font-size-small);
  width: 100%;
}
.topic-select-container .topic-input-field-container .input-fields-container app-case-classification-chip.secondary {
  --case-classification-chip-selected-background-color: var(--mi-light-green-color);
}
.topic-select-container .topic-input-field-container .input-fields-container .topic-one-container {
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
  max-width: 50%;
}
.topic-select-container .topic-input-field-container .input-fields-container .topic-one-container .topic-one-filter-input.hidden {
  width: 5px;
}
.topic-select-container .topic-input-field-container .input-fields-container .separator-container {
  grid-row: 1;
  grid-column: 2;
  padding: 0 10px;
}
.topic-select-container .topic-input-field-container .input-fields-container .separator-container .separator {
  margin: 0;
  font-size: 24px;
  color: var(--mi-color-text-lite);
}
.topic-select-container .topic-input-field-container .input-fields-container .topic-two-container {
  grid-row: 1;
  grid-column: 3;
  overflow: hidden;
  max-width: 50%;
}
.topic-select-container .topic-input-field-container .end-icon-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.topic-select-container .topic-input-field-container .end-icon-container app-icon {
  color: var(--mi-secondary-color-2);
  --icon-size: 30px;
}
.topic-select-container .topic-input-field-container .topic-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 250px;
  min-height: 250px;
  top: 70px;
  left: 0;
  padding-bottom: 20px;
  border-radius: 8px;
  display: none;
}
.topic-select-container .topic-input-field-container .topic-container .content {
  width: 100%;
  height: 100%;
  background-color: var(--mi-white-color);
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.topic-select-container .topic-input-field-container .topic-container .content ion-content {
  --padding-top: 15px;
  --padding-bottom: 20px;
  --padding-start: 15px;
  --padding-end: 15px;
  --background: var(--mi-white-color);
}
.topic-select-container .topic-input-field-container .topic-container .content ion-content::part(background) {
  border-radius: 8px;
}
.topic-select-container .topic-input-field-container.expanded .end-icon-container app-icon {
  transform: rotate(-180deg);
}
.topic-select-container .topic-input-field-container.expanded .topic-container {
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
