import { Inject, Injectable } from '@angular/core';
import { ContentParentType, CreateContentStrategy, CreatePostAction, PostData, UpdatePostData } from '../create-content';
import {
  CreatePostRequest,
  CreatePostSuccessResponse,
  ParentType,
  Post,
  PostType,
  UpdatePostRequest,
  UpdatePostSuccessResponse
} from '../../yeti-protocol/chatter-api';
import { ChatterApiService } from '../../chatter-api.service';
import { ToastMode, ToastService } from '../../toast.service';
import { NavControllerService } from '../../nav-controller.service';
import { ClinicalCase } from '../../yeti-protocol/clinical-case';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
import { PostsDataService } from '../../posts/posts-data.service';
import { AppTranslationService } from '../../app-translation.service';
import { CreateContactsFollowersContentService } from './create-contacts-followers-content-service.service';

@Injectable({
  providedIn: 'any',
})
export class CreateContactsFollowersContentStrategy extends CreateContentStrategy {

  facultyOnly: boolean;

  constructor(
    private createContactsFollowersContentService: CreateContactsFollowersContentService,
    private chatterApiService: ChatterApiService,
    private toast: ToastService,
    private navController: NavControllerService,
    private modalController: ModalController,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private postsDataService: PostsDataService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
  ) {
    super();

    this.contentParentType = ContentParentType.GENERAL;
  }

  openCreatePost(
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean
  ): void {
    this.createContactsFollowersContentService.openCreateContactsFollowersPostPage(
      this.post?._id,
      action,
      postContentText,
      clinicalCase,
      replaceUrl);
  }

  openCreateCase(caseId?: string, replaceUrl?: boolean): void {
    this.createContactsFollowersContentService.openCreateContactsFollowersCasePage(
      caseId,
      replaceUrl,
      this.facultyOnly,
      this.shareCaseInPost,
      this.parentType);
  }

  openAddImages(): void {
    this.createContactsFollowersContentService.openCreateContactsFollowersPostPage(
      null,
      CreatePostAction.ADD_IMAGES,
      undefined,
      undefined,
      undefined);
  }

  openAttachDocument(): void {
    this.createContactsFollowersContentService.openCreateContactsFollowersPostPage(
      null,
      CreatePostAction.ATTACH_DOCUMENT,
      undefined,
      undefined,
      undefined);
  }

  openAddVideo(): void {
    this.createContactsFollowersContentService.openCreateContactsFollowersPostPage(
      null,
      CreatePostAction.ADD_VIDEO,
      undefined,
      undefined,
      undefined);
  }

  get isPostTypeAttachment(): boolean {
    return this.post?.type === PostType.attachment;
  }

  get isPostTypeLink(): boolean {
    return this.post?.type === PostType.link;
  }

  get isPostTypeText(): boolean {
    return this.post?.type === PostType.text;
  }

  createPost(postData: PostData, preventNavigation?: boolean): Promise<Post> {

    postData.parentIds = this.parentIds;
    postData.parentType = this.parentType;
    postData.facultyOnly = this.facultyOnly || false;

    if (!postData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {

      this.chatterApiService.createPost(postData as CreatePostRequest)
        .then(async (createPostRes: CreatePostSuccessResponse) => {
          if (createPostRes) {
            this.toast.show('app.components.CreatePost.post-successfull');
          }

          this.post = createPostRes?.result;
          this.postsDataService.triggerNewPostAddedAction(this.post);

          try {

            await this.modalController?.dismiss({ customClose: true });

            if (!preventNavigation) {
              this.navigateToPost();
            }
          } catch (err) {
            console.log(err);
          }

          resolve(this.post);
        }).catch(err => {
          this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
          reject(err);
        });
    });
  }

  createPostWithACase(postWithCaseData: PostData, preventNavigation?: boolean): Promise<Post> {

    postWithCaseData.parentIds = this.parentIds;
    postWithCaseData.parentType = this.parentType;
    postWithCaseData.facultyOnly = this.facultyOnly || false;

    if (!postWithCaseData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    return new Promise((resolve, reject) => {
      this.chatterApiService.createPost(postWithCaseData as CreatePostRequest)
        .then(async (createPostRes: CreatePostSuccessResponse) => {
          if (createPostRes) {
            this.toast.show('app.components.CreatePost.post-successfull');
          }

          this.post = createPostRes?.result;
          this.postsDataService.triggerNewPostAddedAction(this.post);

          this.modalController?.dismiss({ customClose: true });

          if (!preventNavigation) {
            this.navigateToPost();
          }

          resolve(this.post);
        }).catch(err => {
          this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
          reject(err);
        });
    });
  }

  updatePost(updatePostData: UpdatePostData): Promise<Post> {

    updatePostData.parentId = this.post.parentId;
    updatePostData.parentType = this.post.parentType;

    if (!updatePostData?.parentId?.length) {
      console.error('parentIds is mandatory');
      return Promise.reject('parentIds is mandatory');
    }

    if (!updatePostData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    return new Promise((resolve, reject) => {
      this.chatterApiService.updatePost(this.post?._id, updatePostData as UpdatePostRequest)
        .then(async (updatePostRes: UpdatePostSuccessResponse) => {

          this.post = updatePostRes?.result;
          this.postsDataService.triggerPostEditedAction(this.post);
          resolve(this.post);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  getParentMembers(
    /* eslint-disable */
    searchTerm: string,
    start?: number,
    count?: number,
    excludedUserIds?: Array<string>
    /* eslint-enable */
  ): Promise<Array<any>> {
    return Promise.resolve([]);
  }

  async promptUserAboutNotSavedState(): Promise<any> {
    const title = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-title');
    const text = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-text');
    const confirmButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-confirm-button-text');
    const rejectButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-reject-button-text');

    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: confirmButtonText,
        role: 'confirm'
      },
      {
        text: rejectButtonText,
        role: 'cancel',
        cssClass: 'secondary'
      }]
    });

    alert.present();
    return alert.onDidDismiss();
  }

  determinePostType(url: string, attachments: Array<string>): string {
    if (attachments?.length) {
      return PostType.attachment;
    } else if (url) {
      return PostType.link;
    } else {
      return PostType.text;
    }
  }

  get parentIds(): Array<string> {

    const index = this.parentsData?.findIndex(data => data?.parentsType === ParentType.CHAT || data?.parentsType === ParentType.USER);

    if (index > -1) {
      const parentsIds = this.parentsData[index].parents?.map(parent => {
        return parent?._id;
      })?.filter(id => id !== null && id !== undefined) || [];

      return parentsIds;
    }

    return [];
  }

  get parent(): any {
    const index = this.parentsData?.findIndex(data => data?.parentsType === ParentType.CHAT || data?.parentsType === ParentType.USER);

    if (index > -1) {
      if (this.parentsData[index]?.parents?.length === 1) {
        return this.parentsData[index].parents?.[0];
      } else { // if there is multiple parents return null
        return null;
      }
    }

    return null;
  }

  get allowAnonymousPost(): boolean {
    return false;
  }

  get parentType(): ParentType {
    return this.parentsData?.[0].parentsType || null;
  }

  private navigateToPost(): void {

    const postType = this.post?.type === PostType.case ? 'case' : 'post';
    let postUrl;

    if (postType === 'post') {
      postUrl = `${this.contextService.currentContext.homePath}/post/${this.post?._id}`;
    } else {
      postUrl = `${this.contextService.currentContext.homePath}/shared/case/${this.post?._id}`;
    }

    if (window.location.href.indexOf(postUrl) === -1) {
      this.router.navigateByUrl(postUrl, { replaceUrl: true });
    }
  }
}
