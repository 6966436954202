// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
}

app-mi-card {
  width: 100%;
  --card-padding: 12px;
  --mi-card-padding-top: var(--card-padding);
  --mi-card-padding-bottom: var(--card-padding);
  --mi-card-padding-start: var(--card-padding);
  --mi-card-padding-end: var(--card-padding);
  --mi-card-margin-start: 7px;
  --mi-card-margin-end: 7px;
}
app-mi-card ::ng-deep ion-card {
  display: flex;
  flex-direction: column;
}
app-mi-card ::ng-deep .card-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header-container {
  display: flex;
  padding: 8px;
  border-bottom: solid 1px var(--mi-blue-color-5);
  margin-bottom: 7px;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  min-height: 42px;
}
.header-container .date {
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  flex-grow: 1;
  padding-top: 8px;
}
.header-container .badge {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-medium);
  background-color: var(--mi-purple-color-3);
  color: var(--mi-purple-color-5);
  border-radius: 8px;
  flex-grow: 0;
  padding: 4px 12px;
  margin-top: 4px;
  margin-left: 5px;
}
.header-container .badge.bookmarked {
  background-color: rgba(255, 240, 0, 0.3);
  color: var(--mi-black-color);
}

.title-container {
  padding: 0 8px;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  padding-bottom: 7px;
  min-height: 39px;
}
.title-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 37px;
}
.title-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-container .text {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-empty-state-header-color);
}

.attachments-container {
  padding: 0 8px;
  border-bottom: solid 1px var(--mi-blue-color-5);
  padding-bottom: 10px;
}
.attachments-container app-image-attachments-container {
  max-height: 90px;
  --image-attachments-container-padding-top: 0;
}
.attachments-container app-image-attachments-container ::ng-deep .image-attachments-container .single-image-container .count-badge {
  background-color: rgba(195, 221, 252, 0.8);
}

.case-tags-container {
  padding: 0 8px;
  flex-grow: 2;
}
.case-tags-container app-case-tags {
  --case-tags-padding-start: 0;
  --case-tags-padding-end: 0;
  --case-tags-title-font-size: var(--font-size-tiny);
  --case-tags-title-color: var(--mi-grey-color-13);
  --level-one-chip-selected-background-color: var(--mi-blue-color-4);
  --level-two-chip-selected-background-color: var(
    --mi-content-groups-color-9
  );
}
.case-tags-container app-case-tags ::ng-deep .case-tags-container {
  row-gap: 5px;
}
.case-tags-container app-case-tags ::ng-deep .case-tags-container app-chips ::ng-deep .chips-container app-case-classification-chip {
  --rows-margin: 0px;
}
.case-tags-container app-case-tags ::ng-deep .case-tags-container app-chips ::ng-deep .chips-container app-case-classification-chip ::ng-deep .case-classification-chip-container .chip-text {
  font-weight: var(--font-weight-regular) !important;
  font-size: var(--font-size-tiny) !important;
  color: var(--mi-grey-color-13);
}

app-mi-card-actions {
  --mi-card-actions-height: 47px;
  --mi-card-actions-padding-bottom: 2px;
  flex-grow: 0;
}
app-mi-card-actions .buttons-wrapper {
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 17.5px;
       column-gap: 17.5px;
  align-items: center;
}
app-mi-card-actions .buttons-wrapper app-feature-case-button ::ng-deep .feature-case-button-container .feature-case-button .button-content-container app-icon {
  --icon-size: 20px;
}
app-mi-card-actions .buttons-wrapper app-applaud-button {
  margin-top: -2.6px;
}
app-mi-card-actions .buttons-wrapper app-applaud-button ::ng-deep .applaud-button-container .applaud-button .button-content-container app-icon {
  --icon-size: 16px;
}
app-mi-card-actions .buttons-wrapper app-applaud-button ::ng-deep .applaud-button-container .applaud-button .button-content-container .button-text {
  line-height: 1.2;
  font-size: var(--font-size-tiny);
}
app-mi-card-actions ::ng-deep .mi-card-actions-container .custom-button-actions app-share-button button .share-button-container app-icon {
  --icon-size: 18px;
}
app-mi-card-actions ::ng-deep .mi-card-actions-container .custom-button-actions app-settings-button .settings-button-container .settings-button .settings-button-wrap-vertical {
  row-gap: 2.5px;
}
app-mi-card-actions ::ng-deep .mi-card-actions-container .custom-button-actions app-settings-button .settings-button-container .settings-button .settings-button-wrap-vertical .btn-text {
  font-size: var(--font-size-tiny);
}

.attachments-container ::ng-deep app-image-attachments-container .single-image-container {
  height: 90px !important;
  position: relative !important;
}
.attachments-container ::ng-deep app-image-attachments-container app-image-attachment {
  height: 90px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
