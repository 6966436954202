import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';

import appConfig from '../../config/config';

export enum FruitSaladObjectTypes {
  CASE = 'case'
}

export interface TagTextChangeData {
  fruitSaladObjectType: FruitSaladObjectTypes,
  text: string
}

// models
import {
  CaseFruitSaladaSuggestionType,
  CaseFruitSaladSuggestionsRequestParams,
  CaseFruitSaladSuggestionsResponse
} from './yeti-protocol/fruit-salad';

// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

@Injectable({
  providedIn: 'root'
})
export class FruitSaladService {

  private tagTextChangesStream: Subject<TagTextChangeData> = new Subject();
  private tagSuggestionsStream: Subject<Array<string>> = new Subject();

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService
  ) { }

  getTagTextChangesStream(): Observable<TagTextChangeData> {
    return this.tagTextChangesStream.asObservable();
  }

  triggerTagTextChange(fruitSaladObjectType: FruitSaladObjectTypes, text: string): void {
    this.tagTextChangesStream.next({
      fruitSaladObjectType: fruitSaladObjectType,
      text: text
    });
  }

  getTagtagSuggestionsStream(): Observable<Array<string>> {
    return this.tagSuggestionsStream.asObservable();
  }

  triggerTagSuggestionsChange(suggestions: Array<string>): void {
    this.tagSuggestionsStream.next(suggestions);
  }

  getCaseClassificationSuggestions(
    term: string,
    type: CaseFruitSaladaSuggestionType,
    primaryValue?: string): Promise<CaseFruitSaladSuggestionsResponse> {

    const appId = this.contextService.currentContext.key;
    const postRequestUrl = `${appConfig.chatterUrl}posts/caseClassifications/suggestions`;

    const requestParams: CaseFruitSaladSuggestionsRequestParams = {
      appId: appId,
      type: type,
      term: term || ''
    };

    if (primaryValue) {
      requestParams.primary = primaryValue;
    }

    return firstValueFrom(this.authService.secureGet<CaseFruitSaladSuggestionsResponse>(postRequestUrl,
      { params: toAuthRequestParams(requestParams) }).pipe(
        this.schemaValidator.isValidOperator<CaseFruitSaladSuggestionsResponse>('CaseFruitSaladSuggestionsResponse')
      ));
  }
}
