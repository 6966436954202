import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageType } from '../image-attachments-container/image-attachments-container.model';
import {UI_UTILS_SERVICE, UIUtilsServiceInterface} from '../../services/utils/ui-utils.service.interface';
import {openVideoDialog} from '../../modules/video-hub/video-hub.utils';
import {ImageAttachment} from '../../services/attachments.model';

@Component({
  selector: 'app-image-attachment',
  templateUrl: './image-attachment.component.html',
  styleUrls: ['./image-attachment.component.scss'],
})
export class ImageAttachmentComponent {

  @Input() image: ImageAttachment = null;
  @Input() preventClick: boolean;
  @Input() showUploadPlaceholder = false;
  @Input() withBlurredBackground = false;
  @Input() blurredImage = false;
  @Output() openGalleryEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private domSanitizer: DomSanitizer,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private modalController: ModalController
  ) { }

  onImageClick(event: Event): void {
    if (this.isVideo && this.uiUtilsService.isTargetHavingAncestorWithOpenVideoPreviewClass(event)) {
      this.uiUtilsService.stopEventPropagation(event);
      openVideoDialog(this.image.videoUrl, this.image.mimeType, this.image.previewUrl, this.modalController);
    } else {
      if (this.preventClick) {
        return;
      }
      this.uiUtilsService.stopEventPropagation(event);
      this.openGalleryEvent.emit();
    }
  }

  get isVideo(): boolean {
    return this.image?.type === ImageType.VIDEO;
  }

  get imageUrl(): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(this.image?.previewUrl ?? this.image?.fullUrl);
  }
}
