// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --document-attachment-padding-top: 13px;
  --document-attachments-padding-bottom: 13px;
  --document-attachments-padding-start: 13px;
  --document-attachments-padding-end: 13px;
  --document-attachments-background-color: var(--mi-inputs-color);
  --document-attachments-icon-size: 27px;
  --document-attachments-icon-color: var(--mi-color-text-lite);
  --document-attachments-remove-button-size: 18px;
}

.document-attachment-container {
  width: 100%;
}
.document-attachment-container.show-remove-button {
  display: grid;
  grid-template-columns: auto 7px;
  grid-template-rows: 7px auto;
}
.document-attachment-container .document-info-container {
  grid-row: 2/3;
  grid-column: 1/2;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 5px;
  align-items: center;
  align-content: center;
  padding-top: var(--document-attachment-padding-top);
  padding-bottom: var(--document-attachments-padding-bottom);
  padding-left: var(--document-attachments-padding-start);
  padding-right: var(--document-attachments-padding-end);
  background-color: var(--document-attachments-background-color);
}
.document-attachment-container .document-info-container .document-icon-container {
  grid-row: 1/-1;
  grid-column: 1;
}
.document-attachment-container .document-info-container .document-icon-container app-icon {
  --icon-size: var(--document-attachments-icon-size);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--document-attachments-icon-color);
  margin: 0;
}
.document-attachment-container .document-info-container .file-name-container {
  width: 100%;
  grid-row: 1;
  grid-column: 2;
  align-self: end;
  justify-self: start;
  overflow: hidden;
  position: relative;
  height: 15px;
}
.document-attachment-container .document-info-container .file-name-container p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-black-color);
  line-height: 1;
  margin: 0;
  position: absolute;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.document-attachment-container .document-info-container .content-length-container {
  width: 100%;
  grid-row: 2;
  grid-column: 2;
  align-self: start;
  justify-self: start;
  overflow: hidden;
}
.document-attachment-container .document-info-container .content-length-container p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-black-color);
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.document-attachment-container .remove-button-container {
  grid-row: 1/-1;
  grid-column: 1/-1;
  justify-self: end;
  align-self: start;
}
.document-attachment-container .remove-button-container app-icon-button {
  --icon-button-background-width: var(--document-attachments-remove-button-size);
  --icon-button-background-height: var(--document-attachments-remove-button-size);
  --icon-button-background-color: var(--mi-color-text-lite);
  --icon-button-background-paddng: 2px;
  --icon-button-border-radius: 50%;
  --icon-button-icon-size: 8px;
  --icon-button-icon-color: var(--mi-white-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
