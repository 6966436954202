// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-text-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
}
.edit-text-dialog-container ion-header {
  grid-row: 1;
  grid-column: 1;
}
@media all and (max-width: 600px) {
  .edit-text-dialog-container ion-header ion-title {
    text-align: left;
    padding: 3px 10px;
  }
}
.edit-text-dialog-container ion-header ion-button {
  margin: 0px 2px;
}
.edit-text-dialog-container .content {
  grid-row: 2;
  grid-column: 1;
  position: relative;
}
.edit-text-dialog-container .content .wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.edit-text-dialog-container .content .wrapper ion-textarea ::ng-deep .textarea-wrapper textarea {
  max-height: 251px;
  padding: 10px 20px;
}
.edit-text-dialog-container ion-footer {
  grid-row: 3;
  grid-column: 1;
}

ion-header::after {
  height: 0;
}

ion-footer {
  padding-bottom: 8px;
}

ion-footer::before {
  height: 0;
}

.dialog-header {
  --ion-toolbar-color: var(--mi-secondary-color-2);
}

ion-toolbar {
  --background: var(--mi-white-color);
}

.title {
  font-size: var(--font-size-extra-large);
  color: var(--mi-secondary-color-1);
  text-align: center;
  margin-bottom: 10px;
}

ion-textarea {
  height: 100%;
  overflow: hidden;
  --background: var(--mi-inputs-color);
  --border-radius: 8px;
}

app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 5px;
  --rich-text-document-editor-padding-bottom: 5px;
  --rich-text-document-editor-padding-start: 20px;
  --rich-text-document-editor-padding-end: 20px;
  --rich-text-document-editor-background-color: var(--mi-inputs-color);
}

.counter {
  margin: 4px 16px;
  text-align: right;
  color: var(--mi-success);
}
.counter.is-large {
  color: var(--mi-danger);
}

ion-button {
  border-radius: 3px;
  margin-right: 16px;
}

.cancel {
  --ion-toolbar-color: var(--mi-grey-color-11);
  border: solid 1px var(--mi-grey-color-11);
}

.save {
  --ion-toolbar-color: var(--mi-white-color);
  background: var(--mi-primary-color);
}

.cancel,
.save {
  text-transform: none;
  --padding-start: 16px;
  --padding-end: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
