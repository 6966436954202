import {
  CasesType,
  ClinicalCase,
  GetClinicalCasesSuccessResponse,
} from 'src/app/services/yeti-protocol/clinical-case';

export namespace ClinicalCases {
  export interface FetchClinicalCasesPayload {
    pageIndex?: number;
    pageSize?: number;
    type?: CasesType;
    topicFilter?: string;
  }

  export class FetchClinicalCases {
    static readonly type = '[Clinical Cases] Fetch clinical cases';
    constructor(public payloadParams: FetchClinicalCasesPayload) { }
  }

  export class FetchClinicalCasesSuccess {
    static readonly type = '[Clinical Cases] Fetch clinical cases success';
    constructor(
      public payloadParams: FetchClinicalCasesPayload,
      public response: GetClinicalCasesSuccessResponse
    ) { }
  }

  export class FetchClinicalCasesFailed {
    static readonly type = '[Clinical Cases] Fetch clinical cases failed';
  }

  export class InsertClinicalCaseBeforeIndex {
    static readonly type = '[Clinical Cases] Insert clinical case before index';
    constructor(
      public clinicalCase: ClinicalCase,
      public index: number
    ) { }
  }

  export class RemoveClinicalCase {
    static readonly type = '[Clinical Cases] Remove clinical case at index';
    constructor(
      public index: number
    ) { }
  }

  export class UpdateClinicalCase {
    static readonly type = '[Clinical Cases] Update clinical case';
    constructor(
      public clinicalCase: ClinicalCase
    ) { }
  }
}
