import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

interface CaseSuggestionsParams {
  caseFruitSaladaSuggestionType: CaseFruitSaladaSuggestionType,
  primaryValue?: string
}

// components
import { FruitSaladComponent } from '../dialogs/fruit-salad/fruit-salad.component';

// models
import { FruitSaladObjectTypes, TagTextChangeData, FruitSaladService } from './fruit-salad.service';

// services
import { CaseFruitSaladaSuggestionType, CaseFruitSaladSuggestionsSuccessResponse } from './yeti-protocol/fruit-salad';


@Injectable({
  providedIn: 'root'
})
export class FruitSaladUiService {

  private caseSuggestionsParams: CaseSuggestionsParams;

  constructor(
    private modalController: ModalController,
    private fruitSaladService: FruitSaladService
  ) {
    this.listenForTagTextChanges();
  }

  async openFruitSalad(fruitSaladObjectType: FruitSaladObjectTypes): Promise<string> {

    const fruitSaladModal = await this.modalController.create(
      {
        component: FruitSaladComponent,
        componentProps: {
          fruitSaladForObjectType: fruitSaladObjectType,
        },
        cssClass: 'fruit-salad-dialog'
      });

    fruitSaladModal.present();

    try {
      const res = await fruitSaladModal.onDidDismiss();
      this.removeAllSavedParams();

      if (!res?.data) {
        return '';
      }

      return res?.data;

    } catch (err) {
      this.removeAllSavedParams();
      console.error(err);
      return '';
    }
  }

  async openCaseClassificationFruitSalad(
    caseFruitSaladaSuggestionType: CaseFruitSaladaSuggestionType,
    primaryValue?: string): Promise<string> {

    if (caseFruitSaladaSuggestionType === CaseFruitSaladaSuggestionType.SECONDARY && !primaryValue) {
      console.error('If type is secondary you must include primaryValue');
      return;
    }

    this.setCaseSuggestionsParams({
      caseFruitSaladaSuggestionType: caseFruitSaladaSuggestionType,
      primaryValue: primaryValue
    });

    return this.openFruitSalad(FruitSaladObjectTypes.CASE);
  }

  removeAllSavedParams(): void {
    this.setCaseSuggestionsParams(null);
  }

  private setCaseSuggestionsParams(caseSuggestionsParams: CaseSuggestionsParams): void {
    this.caseSuggestionsParams = caseSuggestionsParams;
  }

  private listenForTagTextChanges(): void {
    this.fruitSaladService.getTagTextChangesStream().subscribe((tagTextChangeData: TagTextChangeData) => {

      if (!tagTextChangeData || !tagTextChangeData.fruitSaladObjectType) {
        return;
      }

      if(tagTextChangeData.fruitSaladObjectType === FruitSaladObjectTypes.CASE){
        this.requestCaseTagSuggestions(tagTextChangeData.text);
      }
    });
  }

  private requestCaseTagSuggestions(term: string): void {

    this.fruitSaladService.getCaseClassificationSuggestions(term,
      this.caseSuggestionsParams.caseFruitSaladaSuggestionType,
      this.caseSuggestionsParams.primaryValue).then((suggestionsResponse: CaseFruitSaladSuggestionsSuccessResponse) => {
        this.fruitSaladService.triggerTagSuggestionsChange(suggestionsResponse.result);
      }).catch(err => {
        console.error(err);
        this.fruitSaladService.triggerTagSuggestionsChange([]);
      });
  }
}
