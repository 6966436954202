import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

// models
import { UserProfile } from './yeti-protocol/auth/mi';
import { AuthService } from './auth/auth.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from './utils/ui-utils.service.interface';
import { CONTEXT_DIALOGS_UI, ContextDialogsUI } from './dialogs/dialogs.ui.interface';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from './dialogs/dialogs.ui.service';
import { VerificationStatus } from './verification.model';
import { VerificationDialogComponent } from '../components/verification-dialog/verification-dialog.component';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  private user: UserProfile;
  private showChangeProfessionDialogSubject: Subject<void> = new Subject();

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private dialogs: DialogsUIService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) {
    this.authService?.userProfileAsObservable?.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  async verify(hidePendingDialog?: boolean, redirectToHomeOnDismiss?: boolean, userProfile?: UserProfile): Promise<string> {

    if (!this.user && userProfile) {
      this.user = userProfile;
    }

    if (!this.user) {
      return null;
    }

    if (this.user?.verificationStatus === VerificationStatus.VERIFIED) {
      return VerificationStatus.VERIFIED;
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      if (!hidePendingDialog) {
        await this.showVerificationPendingDialog(redirectToHomeOnDismiss);
      }
      return VerificationStatus.PENDING;
    }

    return this._verify(redirectToHomeOnDismiss);
  }

  async _verify(redirectToHomeOnDismiss?: boolean): Promise<string> {
    const userWantsToVerify: boolean = await this.showShouldProceedWithVerificationConfirm(redirectToHomeOnDismiss);

    if (userWantsToVerify) {
      if (!this.user?.aoId) {
        await this.showEmailNotVerifiedConfirm();
      } else if (this.user?.profession !== 'Surgeon/Physician' && this.user?.profession !== 'Resident/Fellow'
        && this.user?.profession !== 'Medical Student') {
        const userWantsToChangeProfession = await this.showChangeProfessionDialog();

        if (userWantsToChangeProfession) {
          await this.contextDialogs.showUserSettings()
            .then(signOut => {
              if (signOut) {
                this.uiUtilsService.signOut();
              }
            }).catch(err => {
              console.error(err);
            });
        }

      } else {
        await this.showVerificationDialog(redirectToHomeOnDismiss);
        return this.user?.verificationStatus;
      }
    }
    return null;
  }

  private async showShouldProceedWithVerificationConfirm(redirectToHomeOnDismiss?: boolean): Promise<boolean> {

    const laterActionKey = 'later';
    const verifyActionKey = 'verify';

    const shouldProceedWithVerificationConfirmData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.show-verification-dialog-confirm-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.show-verification-dialog-confirm-message'
        },
        actions: [
          {
            key: laterActionKey,
            label: {
              translationKey: 'app.common.later',
            },
            className: 'secondary'
          },
          {
            key: verifyActionKey,
            label: {
              translationKey: 'app.common.verify-now',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(shouldProceedWithVerificationConfirmData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (redirectToHomeOnDismiss) {
        this.redirectToHomePage();
      }

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === verifyActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private async showEmailNotVerifiedConfirm(): Promise<void> {
    const noAoIdConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.email-confirm-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.email-confirm-message'
        },
        actions: [
          {
            key: 'got-it',
            label: {
              translationKey: 'app.common.got-it',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(noAoIdConfirmDialogData);
    confirmModal.present();
  }

  private async showVerificationPendingDialog(redirectToHomeOnDismiss?: boolean): Promise<void> {
    const verificationPendingConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.pending-verification-confirm-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.pending-verification-confirm-message'
        },
        actions: [
          {
            key: 'got-it',
            label: {
              translationKey: 'app.common.got-it',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(verificationPendingConfirmDialogData);
    confirmModal.present();
    confirmModal.onDidDismiss().then(() => {
      if (redirectToHomeOnDismiss) {
        this.redirectToHomePage()
      }
    });
  }

  async showChangeProfessionDialog(): Promise<boolean> {

    const yesActionKey = 'yes';
    const cancelActionKey = 'cancel';

    const changeProfessionConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.change-profession-confirm-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.change-profession-confirm-message'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel',
            },
            className: 'secondary'
          },
          {
            key: yesActionKey,
            label: {
              translationKey: 'app.common.yes',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(changeProfessionConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === yesActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private async showVerificationDialog(redirectToHomeOnDismiss?: boolean): Promise<void> {
    const verificationModal = await this.modalController.create({
      component: VerificationDialogComponent,
      cssClass: 'verification-dialog'
    });

    verificationModal.present();

    try {
      await verificationModal.onDidDismiss().then(() => {
        if (redirectToHomeOnDismiss) {
          this.redirectToHomePage();
        }
      });
      return;
    } catch (err) {
      console.error(err);
      return;
    }
  }

  private redirectToUserProfilePage(): void {
    const url = `${this.contextService.currentContext.key}/profile`;
    this.router.navigateByUrl(url);
  }

  private redirectToHomePage(): void {
    this.router.navigateByUrl(this.contextService.currentContext.homePath);
  }

  get showChangeProfessionDialogObservable(): Observable<void> {
    return this.showChangeProfessionDialogSubject.asObservable();
  }

  triggerShowChangeProfessionDialog(): void {
    this.showChangeProfessionDialogSubject.next();
  }

}
