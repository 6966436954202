import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { MobilePdfViewComponent } from '../../dialogs/mobile-pdf-view/mobile-pdf-view.component';
import { isMobilePlatform } from 'src/app/services/utils/utils';

// models
import { Platform } from 'src/config/config.model';
import { DocumentAttachment } from '../../services/attachments.model';

import appConfig from 'src/config/config';

interface DocumentAttachmentComponentConfig {
  platform: Platform;
}

@Component({
  selector: 'app-document-attachment',
  templateUrl: './document-attachment.component.html',
  styleUrls: ['./document-attachment.component.scss'],
})
export class DocumentAttachmentComponent {

  @Input() documentInfo: DocumentAttachment;
  @Input() showRemoveButton: boolean;
  @Input() preventClick: boolean;
  @Output() openDocumentLink: EventEmitter<string> = new EventEmitter();
  @Output() removeDocument: EventEmitter<any> = new EventEmitter();

  config: DocumentAttachmentComponentConfig = appConfig;

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private modalController: ModalController
  ) { }

  onDocumentClick(event: Event): void {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.preventClick) {
      return;
    }

    // added this because on many places fullUrl was missing
    if (!this.documentInfo?.fullUrl) {
      this.documentInfo.fullUrl = this.documentInfo?.url;
    }

    if (isMobilePlatform(this.config.platform)) {
      this.displyMobilePdfViewDialog();
    } else {
      this.openDocumentLink.emit(this.documentInfo.fullUrl);
    }
  }

  onRemoveButtonClick(): void {
    this.removeDocument.emit();
  }

  async displyMobilePdfViewDialog(): Promise<void> {
    const groupInvitations = await this.modalController.create({
      component: MobilePdfViewComponent,
      cssClass: 'mobile-pdf-view-dialog',
      componentProps: {
        documentInfo: this.documentInfo
      }
    });
    await groupInvitations.present();
  }
}
