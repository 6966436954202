import { PersonalMediaGalleryDocument } from 'src/app/services/yeti-protocol/personal-media-gallery';

export enum StageOfTreatmentId {
  PRE_OP = 'pre-op',
  INTRA_OP = 'intra-op',
  POST_OP = 'post-op',
  FOLLOW_UP = 'follow-up'
}

export interface StageOfTreatment {
  label: string,
  id: StageOfTreatmentId
}

export interface PersonalMediaGalleryDocumentWithStageOfTreatment
  extends PersonalMediaGalleryDocument {
  stageOfTreatmentId?: StageOfTreatmentId,
  hidden?: boolean;
}
