import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EditTextDialogComponent } from 'src/app/dialogs/edit-text-dialog/edit-text-dialog.component';
import { TextLimit } from 'src/app/pages/case/case.page';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';

export interface CaseTextDetailsFields {
  description: string;
  complainMedicalCondition: string;
  clinicalExam: string;
  notes: string;
  privateAnnotations: string;
  attachPrivateDocuments: () => boolean;
}

@Component({
  selector: 'app-case-text-details',
  templateUrl: './case-text-details.component.html',
  styleUrls: ['./case-text-details.component.scss'],
})
export class CaseTextDetailsComponent {

  @Input() fields: CaseTextDetailsFields;
  @Output() fieldsChanged: EventEmitter<CaseTextDetailsFields> = new EventEmitter();
  @Output() attachPrivateDocumentsClicked: EventEmitter<void> = new EventEmitter();

  TextLimit = TextLimit;

  constructor(
    private modalController: ModalController
  ) { }

  onEditText(titleResourceId: string, fieldName: string, textLimit: number): void {
    this._editText(titleResourceId, fieldName, this.fields[fieldName], textLimit);
  }

  _editText(titleResourceId: string, fieldName: string, text: string, textLimit: number): void {

    const preventSaveWhenEmpty = false;
    const simpleTextArea = false;

    this.showEditTextDialog(titleResourceId, text, textLimit, preventSaveWhenEmpty, simpleTextArea)
      .then(result => {
        if (result === undefined) {
          return;
        }

        this.fields[fieldName] = result;
        this.emitFieldsChanged();
      });
  }

  showEditTextDialog(
    resourceId: string,
    text: string,
    textLimit: number,
    preventSaveWhenEmpty?: boolean,
    simpleTextArea?: boolean): Promise<string | null | void> {
    return new Promise(resolve => {

      return this.modalController.create({
        component: EditTextDialogComponent,
        componentProps: {
          headerResourceId: resourceId,
          text: text || '',
          textLimit: textLimit,
          preventSaveWhenEmpty: preventSaveWhenEmpty,
          simpleTextArea: simpleTextArea
        },
        cssClass: 'rounded-modal small-modal'
      }).then(modalEl => {
        modalEl.onWillDismiss()
          .then(res => {
            if (res.role === 'cancel') {
              return resolve();
            }
            if (res.role === 'save') {
              return resolve(res.data);
            }
            resolve();
          });
        return modalEl.present();
      });
    });
  }

  stripHtmlTagsFromString(content: string): string {
    return stripHtmlTagsFromString(content || '');
  }

  get descriptionPlainText(): string {
    return this.stripHtmlTagsFromString(this.fields?.description);
  }

  get complainMedicalConditionPlainText(): string {
    return this.stripHtmlTagsFromString(this.fields?.complainMedicalCondition);
  }

  get clinicalExamPlainText(): string {
    return this.stripHtmlTagsFromString(this.fields?.clinicalExam);
  }

  get notesPlainText(): string {
    return this.stripHtmlTagsFromString(this.fields?.notes);
  }

  get privateAnnotationsPlainText(): string {
    return this.stripHtmlTagsFromString(this.fields?.privateAnnotations);
  }

  attachPrivateDocuments(): void {
    this.attachPrivateDocumentsClicked.emit();
  }

  private emitFieldsChanged(): void {
    this.fieldsChanged.emit(this.fields);
  }

  get showAttachPrivateDocuments(): boolean {

    if (this.fields && this.fields?.attachPrivateDocuments) {
      return this.fields?.attachPrivateDocuments();
    }

    return false;
  }


}
