import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { io } from 'socket.io-client';

import appConfig from 'src/config/config';
import { AuthenticationHeaders, MIAuthService } from './auth/mi/mi-auth.service';
import { MISocketEvent, Socket } from './socket.model';
import { TimeoutService } from './utils/timeout.service';
import { CaseAccessRequest } from './yeti-protocol/clinical-case';
import { Connection } from './yeti-protocol/connections';
import { AOEvent } from './yeti-protocol/event';
import { Message } from './yeti-protocol/message';
import { Notification } from './yeti-protocol/notifications';
import { DocumentUploadedNotification } from './yeti-protocol/upload';
import { Store } from '@ngxs/store';
import { ClinicalCasesAccessRequests } from '../state/clinical-cases-access-requests/clinical-cases-access-requests.actions';
import { PendingConnectionRequests } from '../state/pending-connection-requests/pending-connection-requests.actions';
import { ToastMode, ToastService } from './toast.service';
import { AppTranslationService } from './app-translation.service';
import { ActiveConversations } from '../state/active-conversations/active-conversations.actions';
import { Activities } from '../state/activities/activities.actions';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {

  messageSubject: Subject<Message> = new Subject();
  connectionRequestSubject: Subject<Connection> = new Subject();
  connectionUpdateSubject: Subject<Connection> = new Subject();
  ongoingAOEventUpdateSubject: Subject<AOEvent> = new Subject();
  caseAccessRequestSubject: Subject<CaseAccessRequest> = new Subject();
  private activityNotificationSubject: Subject<Notification> = new Subject();
  private documentUploadedSubject: Subject<DocumentUploadedNotification> = new Subject();
  private bulkConnectionRequestSubject: Subject<boolean> = new Subject();

  private socket: Socket = null;
  private connectionAccessToken: string = null;
  private platformPauseSub: Subscription;
  private platformResumeSub: Subscription;
  private signedInSub: Subscription;
  private miRefreshTokensSub: Subscription;
  private socketPromise: Promise<Socket> = null;
  private timer = 0;

  constructor(
    private platform: Platform,
    private miAuthService: MIAuthService,
    private timeoutService: TimeoutService,
    private store: Store,
    private toast: ToastService,
    private appTranslationService: AppTranslationService
  ) {
    this.signedInSub = this.miAuthService.isSignedInAsObservable.subscribe(isSignedIn => {
      if (isSignedIn) {
        this._connect().then(socket => {
          this.socket = socket;
        });
      } else {
        this._disconnect();
      }
    });

    this.platformPauseSub = this.platform.pause.subscribe(() => {
      this._disconnect();
    });

    this.platformResumeSub = this.platform.resume.subscribe(() => {
      this._connect().then(socket => {
        this.socket = socket;
      });
    });

    this.miRefreshTokensSub = this.miAuthService.miTokensRefreshObservable.subscribe(() => {
      this._disconnect();
      this._connect().then(socket => {
        this.socket = socket;
      });
    });
  }

  _disconnect(): void {
    if (this.socket) {
      if (this.socket.connected) {
        this.socket.close();
      }
      this.socket = null;
      this.connectionAccessToken = null;
    }
  }

  _connect(): Promise<Socket> {
    if (!this.socketPromise) { // prevent parallel connection calls
      this.socketPromise = this.miAuthService.getAuthenticationHeaders()
        .then(headers => {
          if (headers.access_token) {
            if (this.socket && this.socket.connected && this.connectionAccessToken === headers.access_token) {
              return this.socket; // already connected with the same access_token
            }
            this._disconnect(); // we need this here because after login, two socket connections are live
            return this.createSocket(headers);
          }
        })
        .finally(() => {
          this.socketPromise = null;
        });
    }
    return this.socketPromise;
  }

  get message(): Observable<Message> {
    return this.messageSubject.asObservable();
  }

  get connectionRequest(): Observable<Connection> {
    return this.connectionRequestSubject.asObservable();
  }

  get connectionUpdate(): Observable<Connection> {
    return this.connectionUpdateSubject.asObservable();
  }

  get ongoingAOEventUpdate(): Observable<AOEvent> {
    return this.ongoingAOEventUpdateSubject.asObservable();
  }

  get activityNotification(): Observable<Notification> {
    return this.activityNotificationSubject.asObservable();
  }

  get caseAccessRequest(): Observable<CaseAccessRequest> {
    return this.caseAccessRequestSubject.asObservable();
  }

  get documentUploaded(): Observable<DocumentUploadedNotification> {
    return this.documentUploadedSubject.asObservable();
  }

  get bulkConnectionRequest(): Observable<boolean> {
    return this.bulkConnectionRequestSubject.asObservable();
  }

  ngOnDestroy(): void {
    this._disconnect();
    this.signedInSub.unsubscribe();
    this.platformPauseSub.unsubscribe();
    this.platformResumeSub.unsubscribe();
    this.miRefreshTokensSub.unsubscribe();
  }

  messageRead(chatId: string, messageId: string): void {
    this.socket.emit(MISocketEvent.Read, { chatId, messageId });
  }

  messageRecievedAcknowledge(chatId: string, messageId: string): void {
    this.socket.emit(MISocketEvent.Acknowledge, { chatId, messageId });
  }

  createSocket(authHeaders: AuthenticationHeaders): Promise<Socket> {
    if (!authHeaders) {
      return Promise.reject('User is not authenticated');
    }
    return new Promise((resolve, reject) => {
      const socket = io(appConfig.webSocketServer, {
        transports: ['websocket'],
        autoConnect: false,
        reconnection: false,
        auth: authHeaders
      });
      socket.on('connect', () => {
        console.log('socket connected');
        this.connectionAccessToken = authHeaders.access_token;
        resolve(socket);
      });
      socket.on('connect_error', err => {
        console.log('connect_error');
        console.log(err);
        this.connectionAccessToken = null;
        socket.close();
        reject(err);
      });
      socket.open();
    })
      .then((socket: Socket) => {
        socket.on('disconnect', () => {
          console.log('socket disconnected');
        });
        socket.on(MISocketEvent.Message, data => {
          this.store.dispatch(new ActiveConversations.UpdateActiveConversationsBasedOnNewMessage(data));
          this.messageSubject.next(data);
        });
        socket.on(MISocketEvent.ConnectionRequest, data => {
          this.store.dispatch(new PendingConnectionRequests.InsertIncomingPendingConnectionRequestBeforeIndex(data, 0));
          this.connectionRequestSubject.next(data);
        });
        socket.on(MISocketEvent.ConnectionUpdate, data => {
          this.store.dispatch(new PendingConnectionRequests.UpdateIncomingPendingConnectionRequest(data));
          this.store.dispatch(new ActiveConversations.UpdateActiveConversationBasedOnConnectionChange(data));
          this.connectionUpdateSubject.next(data);
        });
        socket.on(MISocketEvent.EventStart, data => {
          this.ongoingAOEventUpdateSubject.next(data);
        });
        socket.on(MISocketEvent.ActivityNotification, data => {
          this.activityNotificationSubject.next(data);
          this.store.dispatch(new Activities.InsertActivityBeforeIndex(data, 0));
        });
        socket.on(MISocketEvent.CaseAccessRequest, data => {
          this.store.dispatch(new ClinicalCasesAccessRequests.InsertClinicalCaseAccessRequestBeforeIndex(data, 0));
          this.caseAccessRequestSubject.next(data);
        });
        socket.on(MISocketEvent.DocumentUploaded, data => {
          this.documentUploadedSubject.next(data);
        });
        socket.on(MISocketEvent.BulkConnectionRequestSuccessful, () => {
          this.bulkConnectionRequestSubject.next(true);
          this.toast.showWithMessage(
            this.appTranslationService.instant('app.common.bulkConnectToAllCoContributorsSuccess.text'),
            'app.common.bulkConnectToAllCoContributorsSuccess.title',
            ToastMode.SUCCESS,
            false,
            5000);
        });
        socket.on(MISocketEvent.BulkConnectionRequestFailed, () => {
          this.bulkConnectionRequestSubject.next(false);
          this.toast.showWithMessage(
            this.appTranslationService.instant('app.common.bulkConnectToAllCoContributorsError.text'),
            'app.common.bulkConnectToAllCoContributorsError.title',
            ToastMode.ERROR,
            false,
            5000);
        });
        return socket;
      });
  }

  // returns true if next reconnect attempt should be done
  _tryReconnect(): Promise<boolean> {
    console.log('attempt to reconnect socket');
    return this.miAuthService.isSignedIn()
      .then(isSignedIn => {
        if (!isSignedIn) {
          return false;
        }
        if (!this.socket || (this.socket && this.socket.disconnected)) {
          return this._connect()
            .then(socket => {
              if (!socket) { // connection failed
                return true;
              }
              this.socket = socket;
              return false;
            });
        }
        return false;
      });
  }

  _waitAndTryToReconnect(): void {
    if (this.timer === 0) {
      return; // already waiting
    }
    this.timer = this.timeoutService.setTimeout(() => {
      this.timer = 0;
      this._tryReconnect().then(tryAgain => {
        if (tryAgain) {
          this._waitAndTryToReconnect();
        }
      });
    }, appConfig.webSocketReconnectAttemptInterval);
  }
}
